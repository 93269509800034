import {
  type ClientLoaderFunctionArgs,
  json,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { type AxiosResponse } from 'axios';

import { type DtoSingleGamePackResponse } from '@lp-lib/api-service-client/public';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { GlobalAudioDetectionProvider } from '../components/GameV2/apis/AudioDetection';
import { Overworld } from '../components/GameV2/Overworld';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { apiService } from '../services/api-service';
import { type Game } from '../types/game';
import { fromDTOGamePack, fromDTOGames } from '../utils/api-dto';
import { isForbidden, isNotFound, tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected gamepack id');
  }

  let resp: AxiosResponse<DtoSingleGamePackResponse>;
  try {
    resp = await tokenWithRedirect(
      () =>
        apiService.gamePack.getGamePackById(id, {
          blocks: true,
          games: true,
          progression: 'authenticating-user',
        }),
      action.request.url,
      {
        // for now, require authentication. in the future, we may permit unauthenticated access...
        requireAuthentication: true,
        preferRedirect: 'login',
      }
    );
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }

  return { bundle: resp.data };
};

function OverworldInternal() {
  const { bundle } = useLoaderData<typeof clientLoader>();
  const { gamePack, games, progression } = bundle;

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
    <GlobalAudioDetectionProvider />,
  ];

  const navigate = useNavigate();

  const { call: handlePlayGame } = useLiveAsyncCall(async (game: Game) => {
    if (!progression) {
      await apiService.progression.createMyProgression(gamePack.id);
    }

    // TODO(guoqiang): what happen if there is no progress for the game?
    navigate(`/game-packs/${gamePack.id}/play/${game.id}`);
  });

  return (
    <ProvidersList providers={providers}>
      <Overworld
        pack={fromDTOGamePack(gamePack)}
        games={fromDTOGames(games || [])}
        progression={progression}
        onClickGame={handlePlayGame}
      />
    </ProvidersList>
  );
}

export const Component = OverworldInternal;
